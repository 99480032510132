import React, { useEffect, useState } from "react";
import {
  getHours,
  getMinutes,
  format,
  isToday,
  isTomorrow,
  startOfToday,
  startOfTomorrow,
  getDate
} from "date-fns";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Tabs,
  Tab
} from "@material-ui/core";

enum Day {
  Today,
  Tomorrow
}

const App: React.FC = () => {
  const { day, result, loading, setDay } = useFetchDataForDay(Day.Today);

  const display = (store: Store) => (
    <Card key={store.tradingName}>
      <CardContent>
        <div>{store.tradingName}</div>
        <div>{store.ulp}</div>
      </CardContent>
    </Card>
  );

  const dateChanged = (e: any, v: number) => {
    setDay(v);
  };

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h5" component="h1">
          91 ULP in Joondalup and Edgewater
        </Typography>
        <Typography variant="h6" gutterBottom>
          for {day === Day.Today ? "today" : "tomorrow"}
        </Typography>
        <Tabs value={day} onChange={dateChanged}>
          <Tab value={Day.Today} label="Today" />
          <Tab
            value={Day.Tomorrow}
            label="Tomorrow"
            disabled={!isTomorrowAvailable()}
          />
        </Tabs>
        {loading ? <CircularProgress /> : null}
        {!loading && result
          ? result
              .filter(
                r =>
                  r.suburb === "JOONDALUP" ||
                  r.suburb === "EDGEWATER" ||
                  r.suburb === "CURRAMBINE" ||
                  r.brand === "Costco"
              )
              .sort((a, b) => a.ulp - b.ulp)
              .map(display)
          : null}
      </Box>
    </Container>
  );
};

export default App;

const isTomorrowAvailable = () => {
  const time = new Date();
  return getHours(time) >= 15;
};

const urlForDate = (date: Date) => {
  const formattedDate = format(date, "yyyy-MM-dd");
  return `https://servo-data.s3-ap-southeast-2.amazonaws.com/${formattedDate}`;
};

const useFetchDataForDay = (initialDay: Day) => {
  const [day, setDay] = useState<Day>(initialDay);
  const [result, setResult] = useState<Store[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDataForDate = async (date: Date) => {
      setLoading(true);
      const response = await fetch(urlForDate(date));
      const data: Store[] = await response.json();
      const flattenedData: Store[] = data.map((s: Store) => ({
        ...s,
        ulp: s.prices.ULP
      }));
      setResult(flattenedData);
      setLoading(false);
    };

    fetchDataForDate(day === Day.Today ? startOfToday() : startOfTomorrow());
  }, [day]);

  return { day, result, loading, setDay };
};

interface Store {
  brand: string;
  tradingName: string;
  address: string;
  suburb: string;
  lat: number;
  lon: number;
  ulp: number;
  prices: Prices;
}

interface Prices {
  LPG: number;
  PULP: number;
  ULP: number;
  RON98: number;
}
